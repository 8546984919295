import { useAtomValue, useSetAtom } from "jotai";
import { useRef } from "react";
import { animated, useSpring } from "react-spring";
import { ReactComponent as EyeOffIcon } from "../../../../assets/eye-off.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/eye.svg";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import "./styles.scss";

export const NestedAccordion = ({ openObjState, component, children, componentItems, ...props }) => {
  const itemsState = useAtomValue(items_state);
  const setItemsActiveIds = useSetAtom(update_items_activeIds);
  const open = openObjState.state === component._id;
  const accordionThumbnail = itemsState.activeObjs[component._id]?.imageSrc;
  const ref = useRef();

  //toggle accordion function
  let toggleHandler = (e) => {
    // if already open then close it else open it
    openObjState.setState(open ? null : component._id);

    const section = document.querySelector("#BuildSection");
    setTimeout(() => {
      section.scrollTo({ top: e.target?.offsetTop, behavior: "instant" });
    }, 100);
    // scrollIntoView();

    // !open &&
    //   pushGTMAnalyticsEvent({
    //     event: "Nested Accordion Opened - Bradley",
    //     category: component.category,
    //     displayName: component.displayName,
    //   });
  };

  const openAnimation = useSpring({
    from: { opacity: 1 },
    to: { opacity: 1 },
    config: { duration: 300 },
  });

  //rotate animation
  // const iconAnimation = useSpring({
  //   from: {
  //     transform: "rotate(0deg)",
  //     color: "#ffff",
  //   },
  //   to: {
  //     transform: open ? "rotate(180deg)" : "rotate(0deg)",
  //     color: open ? "#10d6f5" : "#fff",
  //   },
  //   config: { duration: "120" },
  // });

  const handleVisibilityToggle = () => {
    if (itemsState.activeObjs[component._id]._id !== "hidden-rib") {
      const hiddenRib = componentItems.find((item) => item._id === "hidden-rib");
      let copy = { ...itemsState.activeIds };
      copy[component._id] = { _id: hiddenRib._id };
      setItemsActiveIds(copy);
    } else {
      const hiddenRib = componentItems[0];
      let copy = { ...itemsState.activeIds };
      copy[component._id] = { _id: hiddenRib._id };
      setItemsActiveIds(copy);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        {props.canToggleVisibility && (
          <>
            {itemsState.activeObjs[component._id]._id === "hidden-rib" ? (
              <EyeOffIcon onClick={handleVisibilityToggle} className="eye-icon" />
            ) : (
              <EyeIcon onClick={handleVisibilityToggle} className="eye-icon" />
            )}
          </>
        )}
        <animated.div
          ref={ref}
          className={`${open && "active"} nested accordion_item  mx-auto hover_effect my-2 flex-fill`}
          style={openAnimation}
          onClick={toggleHandler}
        >
          <div className="accordion_header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {accordionThumbnail && <img className="previewThumbnail me-2" src={accordionThumbnail} alt="Thumbnail" />}
              <h5 className="mb-0">{component.displayName.toUpperCase()}</h5>
            </div>
            {open ? (
              <img className="expandIcon" src="/images/minus.svg" alt="expand icon" />
            ) : (
              <img className="expandIcon" src="/images/plus.svg" alt="expand icon" />
            )}
          </div>
        </animated.div>
      </div>
      {open && children}
    </>
  );
};
