import getSymbolFromCurrency from "currency-symbol-map";
import { useAtom } from "jotai";
import _ from "lodash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { isMobile } from "react-device-detect";
import { animated } from "react-spring";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { items_state, products_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { Boop } from "../Animations/Boop";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";

export const ImageItem = ({ component, item, animation }) => {
  const displayPrice = item.price > 0;
  const [itemsState] = useAtom(items_state);
  const [productsState] = useAtom(products_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const isActive = itemsState.activeIds[component._id]._id === item._id ? "activeImage" : null;

  const handleClick = () => {
    // abort if active item was reclicked
    if (itemsState.activeIds[component._id]._id === item._id) return;

    let copy = { ...itemsState.activeIds };
    copy[component._id] = { _id: item._id };

    if (!_.isEmpty(item.inputs)) copy[component._id].inputs = item.inputs;

    setItemsActiveIds(copy);

    pushGTMAnalyticsEvent({
      event: "Item Click - Thrill",
      page: component.category,
      itemSection: component._id,
      item: item._id,
    });
  };

  const tooltip = (props) => (
    // transitionDelay prevents jitter on safari
    <Tooltip {...props} style={{ zIndex: 3000, transitionDelay: "200ms", ...props.style }} id={`tooltip-details-${item._id}`}>
      {item.displayName}
    </Tooltip>
  );

  return (
    <animated.div style={animation}>
      <Boop boopType="scale" scale={1.02} timing={200}>
        {isActive && <img className="checkMark" alt="check mark" src="/images/checkmark.svg" />}
        {displayPrice && isActive && (
          <p className="priceBlack imagePricePosition">{`${getSymbolFromCurrency(productsState.activeObj?.shopify?.currency?.active ?? "USD")}${
            item.price
          }`}</p>
        )}
        <OverlayTrigger key={item._id} placement="bottom" overlay={isMobile ? <></> : tooltip}>
          <div className={isActive}>
            {Array.isArray(item.imageSrc) ? (
              <ImgWithFallback
                className={`imageItems ${component.category}`}
                src={item.imageSrc[0]}
                fallback={item.imageSrc[1] || item.imageSrc[0]}
                onClick={handleClick}
              />
            ) : (
              <div onClick={handleClick}>
                {item._id.includes("gripper") && (
                  <img className="imageItems overlayTexture" src="/thrill-seekers-assets/textures/gripper-transparent.png" alt={item.displayName} />
                )}
                {item.isNew && !isActive && <img class="new-icon" src="/images/new-icon.png" alt="new" />}
                <img
                  className={`imageItems position-relative ${component.category}`}
                  style={{ borderRadius: item?._id?.includes("color-logo") ? 0 : undefined, border: item?._id === "hidden-rib" ? "2px solid red" : undefined }}
                  src={item.thumbnailSrc || item.imageSrc}
                  alt={item.displayName}
                />
              </div>
            )}
          </div>
        </OverlayTrigger>
      </Boop>
    </animated.div>
  );
};
